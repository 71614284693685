import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'llHyphen' })
export class HyphenPipe implements PipeTransform {

  transform(input: string): string {
    if (input == null || input.length === 0)
        return '-';

    return input;
  }
}
