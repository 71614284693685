'use strict';

export const INCIDENT_OVERALL_STAT_URL = 'api/incidents/stats/overall';
export const BASIC_INFO_URL = 'api/info/basic';
export const CHANGE_INCIDENT_STATE = 'api/incident/change/';
export const DELETE_INCIDENT_URL = 'api/incident/delete/';
export const SAVE_INCIDENT_NOTE = 'api/incident/note/create/';
export const VIEW_USER_URL = 'api/user/view/';
export const DELETE_USER_URL = 'api/user/delete/';
export const VIEW_ALL_USER_URL = 'api/users';
export const VIEW_ALL_USER_URL_WITH_PERMITION = 'api/users_with_permission';
export const CREATE_USER = 'api/user/create';
export const EDIT_USER = 'api/user/edit/';
export const CREATE_BLUK_USERS = 'api/user/create/bulk';
export const VIEW_ALL_INCIDENTS_URL = 'api/incidents';
export const ASSIGNE_ROLE = 'api/assign_role';
export const CHANGE_USER_ROLE = 'api/user/permission/add/';
export const REMOVE_ADMIN_URL = 'api/user/permission/remove/';
export const SERVICE_COUNT_URL = 'api/incidents/stats/count/';
export const SAVE_LOCATIONS = 'api/location/create';
export const VIEW_ALL_LOCATIONS = 'api/location/view_all';
export const DELETE_LOCATIONS = 'api/location/delete/';
export const EDIT_LOCATIONS = 'api/location/edit/';
export const GET_ALL_INICIDENT_TYPES = 'api/incidenttype/view_all';
export const ADD_INICIDENT_TYPES = 'api/incidenttype/create';
export const REMOVE_INICIDENT_TYPES = 'api/incidenttype/delete/';
export const EDIT_INICIDENT_TYPES = 'api/incidenttype/edit/';
export const VIEW_ALL_MEDICAL_TYPES = 'api/medicaltype/view_all';
export const SAVE_MEDICAL_TYPE = 'api/medicaltype/create';
export const DELETE_MEDICAL_TYPE = 'api/medicaltype/delete/';
export const EDIT_MEDICAL_TYPE = 'api/medicaltype/edit/';
export const STAT_INCIDENTYPE_BRANCH_COUNT = 'api/incidents/stats/incidenttypevsbranch';
export const DOWNLOAD_INCIDENT_URL = 'api/incidents/download';
export const GET_INCIDENT_TYPE_COUNTS = '/api/incident/typegraph';
export const EDIT_PROFILE_IMAGE = 'api/user/update/profile_picture/';
export const MAKE_USER_INACTIVE = 'api/user/inactive/';
export const MAKE_USER_ACTIVE = 'api/user/active/';
export const CREATE_ACTION = 'api/incident/action/create';
export const GET_ALL_ACTIONS = 'api/incident/action/all/';
export const GET_ALL_BRANCHES = 'api/branch/view/all';
export const CREATE_BRANCHES = 'api/branch/create';
export const GET_ALL_DEPARTMENTS = 'api/department/view/all';
export const SAVE_DEPARTMENT = 'api/department/create';
export const GET_ALL_LOCATIONS = 'api/location/view_all';
export const SAVE_LOCATION = 'api/location/create';
export const GET_ALL_SUB_LOCATIONS = 'api/sublocation/view/all';
export const CREATE_SUB_LOCATIONS = 'api/sublocation/create';
export const GET_ALL_DESIGNATIONS = 'api/designation/view/all';
export const CREATE_DESIGNATION = 'api/designation/create';
export const GET_ALL_SEVERITY_IMPACT_LEVELS = 'api/severityimpact/view/all';
export const CREATE_SEVERITY_IMPACT_LEVELS = 'api/severityimpact/create';
export const CREATE_INCIDENT = 'api/incident/create';
export const DELETE_SEVERITY_IMPACT_LEVELS = 'api/severityimpact/delete';
export const DELETE_BRANCH = 'api/branch/delete';
export const DELETE_DEPARTMENT = 'api/department/delete';
export const DELETE_LOCATION = 'api/location/delete';
export const DELETE_SUB_LOCATION = 'api/sublocation/delete';
export const DELETE_DESIGNATION = 'api/designation/delete';
export const EDIT_SEVERITY_IMPACT = 'api/severityimpact/edit/';
export const GET_SEVERITY_IMPACT =  'api/severityimpact/view/';
export const GET_INCIDNET_TYPE =  'api/incidenttype/view/';
export const EDIT_INCIDNET_TYPE =  'api/incidenttype/edit/';
export const GET_BRANCH =  'api/branch/view/';
export const EDIT_BRANCH =  'api/branch/edit/';
export const GET_DEPARTMENT =  'api/department/view/';
export const EDIT_DEPARTMENT =  'api/department/edit/';
export const GET_LOCATION =  'api/location/view/';
export const EDIT_LOCATION =  'api/location/edit/';
export const GET_SUB_LOCATION =  'api/sublocation/view/';
export const EDIT_SUB_LOCATION =  'api/sublocation/edit/';
export const GET_DESIGNATION =  'api/designation/view/';
export const EDIT_DESIGNATION =  'api/designation/edit/';
export const GET_ACTION =  'api/actions/view/';
export const MARK_AS_FIXED =  'api/incident/action/fix/';
export const DOWNLOAD_AS_CSV =  'api/actions/download/csv/';
export const CONFIRM_ACTION =  'api/incident/action/confirm/';
export const USER_SEARCH_TEXT_BOX =  '/api/user/search';
export const GET_ACTION_MESSAGES =  '/api/actions/message/view/';
export const SEND_ACTION_MESSAGES =  '/api/actions/message/';
export const CREATE_ANALYSIS = 'api/analysis/create/';
export const GET_ALL_ANALYSIS = 'api/analysis/view/all/';
export const ANALYSIS_MARK_AS_FIXED =  'api/analysis/fix/';
export const ADD_USER_NOTIFICATION_TOKEN = 'api/user/notification/token/add';
export const EDIT_COMPANY = 'api/company/edit';
export const VIEW_COMPANY_LOGO = 'api/company/logo/view';
export const VIEW_ANALYSIS = 'api/analysis/view/';
export const DOWNLOAD_ANALYSIS_FILE = 'api/analysis/file/download/';
export const GET_BRANCH_DEPARTMENTS = 'api/department/branch/department/';
export const GET_DEPARTMENT_DESIGNATIONS = 'api/designation/department/designation/';
export const GET_PERFORMERS = 'api/user/view/responsible/people';
export const GET_INCIDENT_ASSIGNEE = 'api/incident/assignee/';
export const GET_INCIDENT_STAGES = 'api/incident/stage/';
export const GET_CURRANT_USER = 'api/user';
export const SAVE_AUDIT = 'api/audits/create';
export const EDIT_AUDIT = 'api/audits/edit/';
export const GET_AUDITS = 'api/audits/group';
export const GET_GROUP_AUDITS = 'api/audits/groupall/';
export const GET_ACTIVE_AUDITS='api/audits/active';
export const GET_SINGLE_AUDIT = 'api/audits/view/';
export const GET_AUDIT_ANSWER = 'api/audits/get_answer/';
export const DELETE_AUDIT = 'api/audits/delete/';
export const DELETE_AUDIT_QUESTION = 'api/audits/question/delete/';
export const GET_USER_NOTIFICATIONS = 'api/user/notification/view';
export const ADD_INCIDENT_QUESTIONS = 'api/incidenttype/addquestions';
export const GET_INCIDENT_QUESTIONS = 'api/incidenttype/getquestions/';
export const DELETE_INCIDENT_QUESTIONS = 'api/incidenttype/deletequestions/';
export const GET_INCIDENT_STAGE_COUNTS = 'api/incident/getincidentsbystage';
export const GET_INCIDENT_ENVIRONMENT_COUNTS_INBRANCH='api/incidents/stats/incidenttypevsbranch/env';
export const GET_INCIDENT_SAFTY_COUNTS_INBRANCH='api/incidents/stats/incidenttypevsbranch/safty';
export const GET_INCIDENT_NUMERIC_COUNTS='api/incidents/stats/all';
export const GET_INCIDENT_NUMERIC_COUNTS_IN_LOCATION='api/incidents/stats/allinLocation/';
export const GET_ALLRISKBYLOCATION='api/riskanalysis/getallbylocation';
export const GET_SINGLE_ANALYSIS ='api/riskanalysis/getsingle/';
export const GET_ANALYSIS_HISTORY ='api/riskanalysis/gethistory/';
export const ADD_NEW_ANALYSIS ='api/riskanalysis/create';

