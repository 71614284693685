
import { Injectable } from '@angular/core';

@Injectable()
export class UtilsService {

  getUserThumbnail(image_url) {

    if (image_url == null)
      return 'assets/images/general/user_default_thumb.png';
    else
      return image_url;
  }
}
