import {Component, EventEmitter, Input, Output, AfterContentChecked} from '@angular/core';

@Component({
    selector: 'll-pagination',
    templateUrl: './pagination.component.html',
})

export class PaginationComponent implements AfterContentChecked {
    @Input() public pageTotal: number;
    @Input() public pageCurrent: number;
    @Output() public page: EventEmitter<any> = new EventEmitter();
    public paginate: Array<any>;

    ngAfterContentChecked() {
        this.paginate = PaginationComponent.pagination(this.pageTotal, this.pageCurrent);
    }

    /**
     * pagination
     * @param pageTotal
     * @param pageCurrent
     * @param pageShowCurrent
     * @param pageShowAll
     * @returns {Array}
     */
    private static pagination(pageTotal: number, pageCurrent: number = 1, pageShowCurrent = 5, pageShowAll = 20): Array<any> {
        const page = [];
        if (pageTotal > pageShowAll) {
            if (pageCurrent < pageShowCurrent * 2 - 2) {
                for (let i = 1; i <= pageShowCurrent * 2 + pageShowCurrent - 1; i++) {
                    page.push(i);
                }
                page.push('...');
                page.push(pageTotal - 1);
                page.push(pageTotal);
            } else {
                page.push(1);
                page.push(2);
                page.push('...');
                if (pageTotal - pageShowCurrent > pageCurrent + 1) {
                    for (let i = pageCurrent - pageShowCurrent + 1; i < pageCurrent + pageShowCurrent; i++) {
                        page.push(i);
                    }
                    page.push('...');
                    page.push(pageTotal - 1);
                    page.push(pageTotal);
                } else {
                    for (let i = pageTotal - pageShowCurrent * 2; i <= pageTotal; i++) {
                        page.push(i);
                    }
                }
            }
        } else {
            for (let i = 1; i <= pageTotal; i++) {
                page.push(i);
            }
        }
        return page;
    }
}
