import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as AppConst from '../../../app.const';
import { Observable } from 'rxjs';
import { IServerRequest } from '../../interfaces/serverrequest';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class CompanyService {

  constructor(private http: HttpClient) { }

  getLogo(){
    return environment.server_url + AppConst.VIEW_COMPANY_LOGO;
  }

  updateCompanyDetails(companyLogo, isRemoveLogo): Observable<IServerRequest<IBasic>> {

    const formData: FormData = new FormData();
    if(companyLogo)
      formData.append('logo', companyLogo, companyLogo.name);

    formData.append('remove_logo', isRemoveLogo ? "1" : "0");

    return this.http.post<IServerRequest<IBasic>>(environment.server_url + AppConst.EDIT_COMPANY , formData);
  }
}
