import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { NbMenuBag } from '@nebular/theme/components/menu/menu.service';
import { Router } from '@angular/router';
import { NbAuthService, NbAuthJWTToken, NbTokenService } from '@nebular/auth';
import { CompanyService } from '../../../pages/miscellaneous/service/company.service';
import { UserService } from '../../../pages/users/services/user.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: any;
  company_logo;
  items =['fdhfghfgh','fghfghgfhgf','fghfghgfh'];
  notifications;
  numberOfNotification;

  userMenu = [{ title: 'Profile', data: { id: 'profile' }  }, { title: 'Log out',  data: { id: 'logout' } }];
  notificationMenu = [{ title: 'Profile', data: { id: 'profile' }  }, { title: 'Log out',  data: { id: 'logout' } }];
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private analyticsService: AnalyticsService,
              private router: Router,
              private authService: NbAuthService,
              private nbTokenService: NbTokenService,
              private companyService: CompanyService,
              private userService: UserService) {
  }

  ngOnInit() {
    var dat;

   this.userService.getUserNotification()
    .subscribe((data) => {
      dat=data;

      this.notifications =dat.reverse();
      this.numberOfNotification=Object.keys(data).length;
    })

    this.company_logo = this.companyService.getLogo();

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload(); // here we receive a payload from the token and assigne it to our `user` variable
        }
      });

    this.menuService.onItemClick().subscribe((item: NbMenuBag) => {
      if (item.item.title === 'Profile' && item.item.data.id === 'profile') {
        this.router.navigateByUrl('/pages/user/' + this.user.id);
      }else if (item.item.title === 'Log out' && item.item.data.id === 'logout') {
        this.nbTokenService.clear(); // This is not good in here move somewhere else.
        this.router.navigateByUrl('/auth/logout');
      }
    });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  failedToLoadImage(){
    this.company_logo = "assets/images/logo.jpg";
    this.company_logo = "assets/images/default_company_logo.png";
  }
 notificationclick = function (action){
  window.open(action);
  console.log(action);
}

}
