
import { Injectable } from '@angular/core';

import * as AppConst from '../../../app.const';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { IServerRequest } from '../../interfaces/serverrequest';
import { IUser } from '../../interfaces/user';
import { IPagination } from '../../interfaces/pagination';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IEmpty } from '../../interfaces/empty';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as firebase from 'firebase';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})

export class UserService {

  //messaging = firebase.messaging()
  currentMessage = new BehaviorSubject(null)

  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth,  private http: HttpClient) { }

  userIncidents(userId): Observable<IServerRequest<IPagination<IUser>>> {
    const data = {'user_id': userId};
    return this.http.post<IServerRequest<IPagination<IUser>>>(environment.server_url + AppConst.VIEW_ALL_INCIDENTS_URL, data);
  }

  viewUser(id): Observable<IServerRequest<IUser>> {
    return this.http.get<IServerRequest<IUser>>(environment.server_url + AppConst.VIEW_USER_URL + id);
  }

  getUsers(): Observable<IServerRequest<IPagination<IUser>>> {
    return this.http.get<IServerRequest<IPagination<IUser>>>(environment.server_url + AppConst.VIEW_ALL_USER_URL);
  }
  getUserswithpermission(): Observable<IServerRequest<IPagination<IUser>>> {
    return this.http.get<IServerRequest<IPagination<IUser>>>(environment.server_url + AppConst.VIEW_ALL_USER_URL_WITH_PERMITION);
  }

  makeRoles(userRoles,id): Observable<IServerRequest<IEmpty>> {
    const roles = {
      'roles': userRoles
    }
    return this.http.post<IServerRequest<IEmpty>>(environment.server_url + AppConst.CHANGE_USER_ROLE + id,roles);
  }

  removeAdmin(id): Observable<IServerRequest<IEmpty>> {
    return this.http.get<IServerRequest<IEmpty>>(environment.server_url + AppConst.REMOVE_ADMIN_URL + id);
  }

  createUser(name,email,branch,usercode,telephone,department,designation,immreportingperson,safetymanager,envmanager,qualitymanager,nic): Observable<IServerRequest<IEmpty>> {

      const data = {'name': name,
                    'email' : email,
                    'immidiatly_reporting_person':immreportingperson,
                    'usercode': usercode,
                    'department': department,
                    'branch' : branch,
                    'designation': designation,
                    'nic':nic
                  };

      if(safetymanager != null){
        data['safety_manager'] = safetymanager;
      }

      if(envmanager != null){
        data['environment_manager'] = envmanager;
      }

      if(qualitymanager != null){
        data['quality_manager'] = qualitymanager;
      }

      if (telephone != null && telephone.trim().length > 0)
        data['telephone'] = telephone;

    return this.http.post<IServerRequest<IEmpty>>(environment.server_url + AppConst.CREATE_USER, data).pipe(
      catchError(this.errorHandler),
    );
  }

  deleteUser(id): Observable<IServerRequest<IEmpty>> {
    return this.http.delete<IServerRequest<IEmpty>>(environment.server_url + AppConst.DELETE_USER_URL + id);
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error);
  }

  editUser(id,name,email,branch,usercode,telephone,department,designation,immreportingperson,safetymanager,envmanager,qualitymanager,nic, removeProfilePic): Observable<IServerRequest<IEmpty>> {

    const data = {'name': name,
                  'email' : email,
                  'immidiatly_reporting_person':immreportingperson,
                  'usercode': usercode,
                  'department': department,
                  'branch' : branch,
                  'designation': designation,
                  'nic':nic,
                  'remove_profile_pic' : removeProfilePic};

    if(safetymanager != null){
      data['safety_manager'] = safetymanager;
    }
            
    if(envmanager != null){
      data['environment_manager'] = envmanager;
    }
            
    if(qualitymanager != null){
      data['quality_manager'] = qualitymanager;
    }

    if (telephone != null && telephone.trim().length > 0)
      data['telephone'] = telephone;

  return this.http.post<IServerRequest<IEmpty>>(environment.server_url + AppConst.EDIT_USER + id, data).pipe(
    catchError(this.errorHandler),
    );
  }

  updateProfileImage(file, userId): Observable<IServerRequest<IPagination<IUser>>> {
    const formData: FormData = new FormData();
    formData.append('attachment', file, file.name);
    return this.http.post<IServerRequest<IPagination<IUser>>>(environment.server_url + AppConst.EDIT_PROFILE_IMAGE + userId, formData);
  }

  createBulkUsers(file): Observable<IServerRequest<IEmpty>> {

    const formData: FormData = new FormData();
    formData.append('attachment', file, file.name);
    return this.http.post<IServerRequest<IEmpty>>(environment.server_url + AppConst.CREATE_BLUK_USERS, formData).pipe(
      catchError(this.errorHandler));
  }

  makeUserInactive(userId): Observable<IServerRequest<IEmpty>> {
    return this.http.post<IServerRequest<IEmpty>>(environment.server_url + AppConst.MAKE_USER_INACTIVE + userId, {});
  }

  makeUserActive(userId): Observable<IServerRequest<IEmpty>> {
    return this.http.post<IServerRequest<IEmpty>>(environment.server_url + AppConst.MAKE_USER_ACTIVE + userId, {});
  }

  addUserNotificationToken(token) : Observable<IServerRequest<IEmpty>> {
    console.log("add token")
    console.log(token);
    console.log(environment.server_url + AppConst.ADD_USER_NOTIFICATION_TOKEN);
    return this.http.post<IServerRequest<IEmpty>>(environment.server_url + AppConst.ADD_USER_NOTIFICATION_TOKEN, {"token" : token});
  }

 getUserNotification() :Observable<IServerRequest<IEmpty>> {
   const data= this.http.get<any>(environment.server_url + AppConst.GET_USER_NOTIFICATIONS);
    return data;
  }
  getPermission() {
    if(firebase.messaging.isSupported()){
      firebase.messaging().requestPermission()
    .then(() => {
      const token = firebase.messaging().getToken();
      return token;
    })
    .then((token) =>{
      this.addUserNotificationToken(token).subscribe(console.log);
      console.log(token);
    })
    .catch((err) => {
      console.log('Unable to get permission to notify.', err);
    });
  }
  }

  receiveMessage() {
    if(firebase.messaging.isSupported()){
      firebase.messaging().onMessage((payload) => {
      console.log("Message received. ", payload);
      this.currentMessage.next(payload["notification"])
    });
  }
  }
}
